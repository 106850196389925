import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/Record/Photoseries/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Set up an automated snapshot recording.",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/Record/Photoseries/' locationFR='/fr/Software/macOS/InstarVision/Record/Photoseries/' crumbLabel="Photoseries" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQI143HsQ6CMBhF4f9V9Pkc2DSwO9A61bAQJngVF9YONPEBjCsFSWnIMRHjaLjJl9wjLAEmvwojvPr1E9k6YwynNIM4Icp6tB3Qbka7+KPcjOrCX3kXuNwXsrPmmBzIrUekeiLlA6l7pPFI/dVsNbC/3tgV7aff3kvA79KvXEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/e4706/Banner_en-InstarVision2-0_Record.avif 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/d1af7/Banner_en-InstarVision2-0_Record.avif 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/7f308/Banner_en-InstarVision2-0_Record.avif 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/e1c99/Banner_en-InstarVision2-0_Record.avif 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/76ea5/Banner_en-InstarVision2-0_Record.avif 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/f696c/Banner_en-InstarVision2-0_Record.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/a0b58/Banner_en-InstarVision2-0_Record.webp 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/bc10c/Banner_en-InstarVision2-0_Record.webp 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/966d8/Banner_en-InstarVision2-0_Record.webp 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/445df/Banner_en-InstarVision2-0_Record.webp 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/78de1/Banner_en-InstarVision2-0_Record.webp 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/882b9/Banner_en-InstarVision2-0_Record.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/81c8e/Banner_en-InstarVision2-0_Record.png 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/08a84/Banner_en-InstarVision2-0_Record.png 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/b1001/Banner_en-InstarVision2-0_Record.png 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/161ec/Banner_en-InstarVision2-0_Record.png 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---ftp-photoseries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---ftp-photoseries",
        "aria-label": "side navigation   ftp photoseries permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - FTP Photoseries`}</h2>
    <p>{`This menu allows you to activate the software´s photo series function.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "470px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/f96db/Image044.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQoz12Ry4scNxDG++8NCTi5G2LDJiTgQwz20QeTBHzwISTgnHJIIARjw3q93vXO4sdMT7pbz5JK0rSkklqmB/viH3Wpor6PenRaawAQQsQ5tJXlU3zOsiyfpZ1Hy6cxzYe/N+bxKf5+hr8+w4dP8fI9Cw4BgHOujsSUHK4VvQKllM6ENOoQM/37Lv52UZ5clUdn9efTerWT8eCttVxwKaVWKqUcQgAApZQGU2vt7Ms/3PNf4O1zLWUw3IPwmgVgUjDGuRBCKWWMkVJoAMaYlHJ15Fxr6Mx/D+xfP9jNP0waKVfTYWQTW6WMMcE5W/s05wzAsKMhAIzjCABdaS3WNmdyDhHRWktUwMB2ux2G/3e7HSK21hBxv+/3+/12uwOtl9bWnSnNAc3Bo5SSMzZNbI7ROWeM8d6L45A5J+ccOheC1xqMMUSUc+6IqJQyxxkRHTqLmFICgL7vp2ns+95aW2u11g5Hdn0PsJ6aiD6KKWchxDRNwzDGGBGRM6aUHIbhuAhZa9ebaTWMo1Lqk7gU68MwMQUgNAgha63iiAuBSTlxkUqNmUqtqVSqS6JyyJSJulrLUmK2QN612XlrU17/GcJhqbSkQB5bq8tCtVBr1BZqS2mt1Erda5O+fOHvXM8/bg43zsKFza1SotKW8rifvzj1J5eHr1/4k0u/sembM3/7Inz/Onx16p6p2L3B9O053r929zbu9iu8MrFViplapT+Hw81zvLtxJ6/c/Wv/FtN3F+6nK3/v2t86x5c6fgC1cM79zOFO0AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e80b2a02e6ff6ac9300bde08e4518af7/e4706/Image044.avif 230w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/d1af7/Image044.avif 460w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/d5421/Image044.avif 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e80b2a02e6ff6ac9300bde08e4518af7/a0b58/Image044.webp 230w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/bc10c/Image044.webp 460w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/4424c/Image044.webp 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e80b2a02e6ff6ac9300bde08e4518af7/81c8e/Image044.png 230w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/08a84/Image044.png 460w", "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/f96db/Image044.png 470w"],
              "sizes": "(max-width: 470px) 100vw, 470px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e80b2a02e6ff6ac9300bde08e4518af7/f96db/Image044.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#schedule",
        "aria-label": "schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Schedule`}</h3>
    <p>{`First select your camera - or keep `}<strong parentName="p">{`All Channels`}</strong>{` selected to set all your camera´s at once - and check `}<strong parentName="p">{`FTP Photoseries`}</strong>{` to start a continuous snapshot recording within the selected time window. Clicking on `}<strong parentName="p">{`Default`}</strong>{` will activate a 24/7 alarm schedule. You can adjust this schedule by first selecting the day of the week you want to change and then select a time window below. You can either select two time windows - e.g. from 12 at night to 7am and from 6pm to 11:59pm to keep an eye on your office over the off-hours. Or hide the second timeline to cover a continuous time period. Please make sure that the first time value always has to be earlier then the second and the time window has to be contained by the 24h day - e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Time Segment 1 08:00:00AM - 06:00:00AM`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Time Segment 2 08:00:00PM - 06:00:00AM`}</code>{` would `}<strong parentName="p">{`NOT`}</strong>{` be valid entries.`}</p>
    <h3 {...{
      "id": "photoseries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#photoseries",
        "aria-label": "photoseries permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Photoseries`}</h3>
    <p>{`InstarVision can be used to take snapshots with an interval between 5s and 900s. Snapshots taken will be named by a time stamp and uploaded to a FTP server (please check the `}<strong parentName="p">{`Save to Disk`}</strong>{` box to save of those snapshots locally). Please go to the `}<a parentName="p" {...{
        "href": "/en/Software/macOS/InstarVision/Record/FTP/"
      }}>{`Record FTP settings`}</a>{` to set up a connection to your FTP server first! You can also upload images directly to your website. To do so, you should set a fixed file name in the FTP settings and then upload it to your webserver. Add the snapshot to your website with an image tag - this way you will always have an up-to-date image on your homepage.`}</p>
    <h3 {...{
      "id": "how-to-create-a-timelapse-video-from-a-photoseries-in-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-create-a-timelapse-video-from-a-photoseries-in-windows",
        "aria-label": "how to create a timelapse video from a photoseries in windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to create a Timelapse Video from a Photoseries in Windows?`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ca250ef7e70129a8e02cb3fd35541a11/2bef9/InstarVision-Photoseries_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9AHJ7hVphZXmGkYeNj5KQjLm3tYqJg3R1bGRlV46gtIipzHqp2IOq0nylz3mkzJOrxpirw5auyqqvtrGvrgDBw8jN0NbW2NjY2trg4uHn6Ofj4+WNj31kYEZ8h41Ybnh3oMKUw+yi0v2w2/+22Piwz+2lyevW3uXo5+UA49jM8uvv8u7w7Obr9PP0////////dXZiZVQtjoFocnBQY2A8dHJWentjjo98prCxpL3TnbzZ4ejv+fj2AOnm5f////uam/23uPz///78+/7+/3p6ZVhTKmRhS2prVWxsWHF1aG10bm58fHeIjnePoXCNpd7k6f///gDp6+v////+Jib/UVH+/////fz///93flNHWA5jZFFcX1ddZmRneYVshZp0jqN6kKJziJhpgJHc4eX//v0A6enp/////jk5/2Vl/v////38////dYV4Z3VWgYaCcHd9YHODcICOaH2Pa3+SgIuVhY6Ue4aQ3+Lk/v79AOnl5f////5WVv+EhP7////8/fv49rPN6YKTrrLC1aHN+Im248LX68rZ58nV4s7Z5NLd5sPO3Ons7/z8+wDq5ub////+Xl7/ior+///++vv///+EiIwyLzJoc32Jna18kKF9kaCXp7KvwM2WnqKBjJBvd3be4N////8A5OTk/P39++jp/vHx/v///v7+////dn+IGh0hHBoSJycdODwyLjMkOT8yWmNcSUk6Q0EyQDsl1NXQ////ANvV1PTy9/v7/P////39/vb5//7+/YCUqjdDTyMtFCEzDEhRLF9dSU5gIkRbFExgIEhbH0tgIqCkl728vwByiZaEgoOEhIWEg4SAgoZ5fYOBhIdKUl01QE0vNS8qMSU6PjVGQ0Q8QTQ1Qik+TS1GVDBDUC9hZF9wb3ClkKJupbKelQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca250ef7e70129a8e02cb3fd35541a11/e4706/InstarVision-Photoseries_01.avif 230w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/d1af7/InstarVision-Photoseries_01.avif 460w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/7f308/InstarVision-Photoseries_01.avif 920w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/e3723/InstarVision-Photoseries_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ca250ef7e70129a8e02cb3fd35541a11/a0b58/InstarVision-Photoseries_01.webp 230w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/bc10c/InstarVision-Photoseries_01.webp 460w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/966d8/InstarVision-Photoseries_01.webp 920w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/a9a89/InstarVision-Photoseries_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ca250ef7e70129a8e02cb3fd35541a11/81c8e/InstarVision-Photoseries_01.png 230w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/08a84/InstarVision-Photoseries_01.png 460w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/c0255/InstarVision-Photoseries_01.png 920w", "/en/static/ca250ef7e70129a8e02cb3fd35541a11/2bef9/InstarVision-Photoseries_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ca250ef7e70129a8e02cb3fd35541a11/c0255/InstarVision-Photoseries_01.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1`}</strong>{` - Open the Start Menu and search for the Windows App `}<strong parentName="p">{`Photo Gallery`}</strong>{`. In Windows 8 just open the Charm Bar on the right of your display and use the Search function embedded there. Start the software.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85d66fd5136e052a9671d445c86af443/2bef9/InstarVision-Photoseries_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQoz5VQUWsTQRDe/yL46pvPIki11rbaUmrTpuFapVBRUxWKIiiW4IMoLUW0piaN0EQJ2Fzi71CfxAcLoeQuvdzebu72cnc7u7dyCaKIYPvxMczMNx8zDLr7MJddffSyUHm19ebtdr5e1zc313O5x2trD14XytvlxoudTxulxvNiY6PUKFb0nfe1YmnvQ1Wv7X1E01fGP1eqvYhT6pp2l3YZZlETs6S0yLcfJqY+psx1Xc55GEUAgIn3ff+gbRro6umT5MJ5LmQC0YeUAKKfSw4CoN8SA10qpajLjA4JgwAtj5zCszNKqTiO1f8wmHFo17BwLAXS5k4crq4kgpRHNGNCW21LCIFmUhOHX7/8czMIGXIAGf+9mdCW2U7M+XzewvaxzK7n2Q4BAFSvVXGno5SSfeVP/vb8ohyc7TiGaUopUe7JfUKcYz3Mtq1mcz+WMbq5rDUPWlyoXsh7ESQMIQiT6AfA/KjLApeFnh8yn3t+GIFqmca73YKu15G2sDh6eXJqTpvUsqOZeyPpO5fS2bH07fH0jeHpoeGJs5n52aVrC4vXU2OZM6mVoflbF6eWzj1bf1reLf8EPEI0Rozws5MAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85d66fd5136e052a9671d445c86af443/e4706/InstarVision-Photoseries_02.avif 230w", "/en/static/85d66fd5136e052a9671d445c86af443/d1af7/InstarVision-Photoseries_02.avif 460w", "/en/static/85d66fd5136e052a9671d445c86af443/7f308/InstarVision-Photoseries_02.avif 920w", "/en/static/85d66fd5136e052a9671d445c86af443/e3723/InstarVision-Photoseries_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85d66fd5136e052a9671d445c86af443/a0b58/InstarVision-Photoseries_02.webp 230w", "/en/static/85d66fd5136e052a9671d445c86af443/bc10c/InstarVision-Photoseries_02.webp 460w", "/en/static/85d66fd5136e052a9671d445c86af443/966d8/InstarVision-Photoseries_02.webp 920w", "/en/static/85d66fd5136e052a9671d445c86af443/a9a89/InstarVision-Photoseries_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85d66fd5136e052a9671d445c86af443/81c8e/InstarVision-Photoseries_02.png 230w", "/en/static/85d66fd5136e052a9671d445c86af443/08a84/InstarVision-Photoseries_02.png 460w", "/en/static/85d66fd5136e052a9671d445c86af443/c0255/InstarVision-Photoseries_02.png 920w", "/en/static/85d66fd5136e052a9671d445c86af443/2bef9/InstarVision-Photoseries_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85d66fd5136e052a9671d445c86af443/c0255/InstarVision-Photoseries_02.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2`}</strong>{` - If you saved your snapshots in a default photo directory, the Gallery app should already display them - you can `}<strong parentName="p">{`skip to step 5`}</strong>{`. Otherwise open the File Menu to add the directory that contains the images from your Photoseries.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/83c1eca21444c25bbb3fefccb9d01555/2bef9/InstarVision-Photoseries_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2UlEQVQoz4WPy2sTURSH579y5UJEKtRooJpaqXaSDtSC7yhiwU0rzULwQVGkNZG0KTJBaqVJ49KFC7ciFmkhrQmTmWQmdybjzH3fuTKNtl0Y/PEtLpzz3XOOcvfh/PUHs69Ka4v5wpvC681q5eWL57nc7NzczGKxXCjXnqx8XFitPV2uLZRqRb26rG8USxW9vL7+fk25MDb6+dMXaHYCtwcxRQjZPVjv+C7wDAt83zE6IHCA73k9TAhEmFBqA397t2lZppJIHP/x9ZtttM1WO4AIIoQxoVzwOIIyzuJ3jBAR55GU0vVD0/EwgsqkNrSzt8ulhDj+OISIUioHJIpi2QGeaYNICGXi1rGt7S3BI4hwXybkP7INXMPqcM6VsYnU3s+6iGQY77w/mbF+K+MCE8b2Vz0qd4Hb6sv5pSXbtg8KRzNI9v0AuD3GmLLx4V2360gphYhLMfIPh85fRP9mxzFNUwihzD+acV3wz8mDbm5bZqNRjyKp3LiWaRgtJiQiDBOO6AECER4i5kP8C5IA0hCxAFEmZLPVXFnNVyubSkabTI6kLl7RUlo2mbmfUO+dVbNJ9c6IenP40unh8yfT6uXpKU2bGj+XPjGeHUrfPjN69dTjZzn9rf4bGkw2jXwq9AAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83c1eca21444c25bbb3fefccb9d01555/e4706/InstarVision-Photoseries_03.avif 230w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/d1af7/InstarVision-Photoseries_03.avif 460w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/7f308/InstarVision-Photoseries_03.avif 920w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/e3723/InstarVision-Photoseries_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/83c1eca21444c25bbb3fefccb9d01555/a0b58/InstarVision-Photoseries_03.webp 230w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/bc10c/InstarVision-Photoseries_03.webp 460w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/966d8/InstarVision-Photoseries_03.webp 920w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/a9a89/InstarVision-Photoseries_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83c1eca21444c25bbb3fefccb9d01555/81c8e/InstarVision-Photoseries_03.png 230w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/08a84/InstarVision-Photoseries_03.png 460w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/c0255/InstarVision-Photoseries_03.png 920w", "/en/static/83c1eca21444c25bbb3fefccb9d01555/2bef9/InstarVision-Photoseries_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/83c1eca21444c25bbb3fefccb9d01555/c0255/InstarVision-Photoseries_03.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3`}</strong>{` - To add your photoseries folder to the selection, choose to `}<strong parentName="p">{`Include a Folder`}</strong>{` in the File menu. This will open the Add Directory dialogue for you.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f743453005280e10bc2d1a1246a3b47c/2bef9/InstarVision-Photoseries_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/0lEQVQoz12RS09TQRiGz98ybjWa1HgDTAGVlqKAslCj1ejGkBg3bIwuUBYYEhWkhcakgUAXyBooG3dA2/RyTntunTlzpnMfclollSfv5su8T74vGePpm3ezc5/WNnZ+5fObG/niwf762s/FxS8LCx9zmzv53wfft4qrheLy9mGmcLi1u7+9u5cr7GVy+dWVZWNgOP6n3KCEorBjgxCFBBBpIUYp82Gn3GiHVCEiQoyV1kIqrVRAxEnVLJdOjGuxi06rJbjgnDPGI4SwHdeyLNO06qbZME3fdkQXLoSSEobEaQe+5xnjyUswAFprpZTuIoSoVGpHR6Xj41KlXKnXala1KruvvY4PkePDEAVG8tkF3EH9MufccWwAEZcKU04ARPWG6JcBtF0fBYExfP8OCs/Lru/YAW53uIsowDRESAhx1mlDaLteJH+en0co6JcZY4QQ3Qel9J8cjRgTEKAAQmM9u4JxeG6z7biuDwCEAADP8xzXlVKedTzfb7aaCCFj9u1LSjr6PxRjlBJCaZQOxpT+PaQnt1pmrVbGITYeTycg6v6hUkLpXpSOIrXmSjOpmVCESco14ZHcdMxvPxazmaxxb2xsKD46mpwamUwPPnx9K/Xqdio9mHoxNP7k+t3YjfiViVRiZnpy6lFi4MHlRDo28fzmyMzVuQ/vl74unQLn9znjCmi3UAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f743453005280e10bc2d1a1246a3b47c/e4706/InstarVision-Photoseries_04.avif 230w", "/en/static/f743453005280e10bc2d1a1246a3b47c/d1af7/InstarVision-Photoseries_04.avif 460w", "/en/static/f743453005280e10bc2d1a1246a3b47c/7f308/InstarVision-Photoseries_04.avif 920w", "/en/static/f743453005280e10bc2d1a1246a3b47c/e3723/InstarVision-Photoseries_04.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f743453005280e10bc2d1a1246a3b47c/a0b58/InstarVision-Photoseries_04.webp 230w", "/en/static/f743453005280e10bc2d1a1246a3b47c/bc10c/InstarVision-Photoseries_04.webp 460w", "/en/static/f743453005280e10bc2d1a1246a3b47c/966d8/InstarVision-Photoseries_04.webp 920w", "/en/static/f743453005280e10bc2d1a1246a3b47c/a9a89/InstarVision-Photoseries_04.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f743453005280e10bc2d1a1246a3b47c/81c8e/InstarVision-Photoseries_04.png 230w", "/en/static/f743453005280e10bc2d1a1246a3b47c/08a84/InstarVision-Photoseries_04.png 460w", "/en/static/f743453005280e10bc2d1a1246a3b47c/c0255/InstarVision-Photoseries_04.png 920w", "/en/static/f743453005280e10bc2d1a1246a3b47c/2bef9/InstarVision-Photoseries_04.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f743453005280e10bc2d1a1246a3b47c/c0255/InstarVision-Photoseries_04.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`4`}</strong>{` - - Click on `}<strong parentName="p">{`Add`}</strong>{` to include your Photoseries folder in the selection. Navigate to the folder where you saved your snapshots and select it. Then click OK.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/20dbd243edffbecd4f474a8c9572beb4/2bef9/InstarVision-Photoseries_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVQozzWOa0/TYACF+xv4P0ZFQxQ0WYQIxm1BLtEEFY3gB4zRSMQPRiNBjRdAQQRHjKgwJiS6OGfH2NrSrt3GLp2bu3Tr23a9bOsu3WsW4snJyfPlSQ4ydnvq8s07z+eWX7x6PT8/u2m3P3s6PTV1997kxMu3q3M2x6PFbzNLjscLjpklx4LNvmjbWHi3/mF17fPaJ6TXfB7dY1SWBbyQFTVZloFSSollVVGYpOzeiRaBFC/qmqLoerVc0au1WgFI+/Ekx+UQ05mjIYKA4+MgleZ4QRRESSryQJBECQBJACLgBSBIiqIazWaj0YAQcgUhzQFdryDDV0yJr2uwrY1ZXvEHwwRGkCTl82EEQWI4QQdonx/HMDwajUEIDcOAEObyfDYPmoaBDN9qLwRweKRdjoYlRdCKfFmTSopQVsTWqpImg7IqarJQ0jRVkSvl8t90JpvnG40GYr3UnwsysLMzuOvBfSjpRwO4F/P8In0eYvd3kPQTXjeNe0k/Gg3TDIlFghSJ+wtAbNTryOzsXJ6Nw+npGLUXpMkwTUVCNEMRQYpIsa2rzWYT/s8Bi5IEBKklr3/5CAAPIVRVVZaVUkmr6rpeKet6papX6rXaQWvVWrVa0/WqYRjZbDabyxqGgdyfnBCElkwR/h33T5/HjXlR1OX0edxetwvf3UFdTsyLupzO7z9c9s3trW3npmOL4zIt+erIQCqdqTdhKJogmSizz4YiiUAwxoRZJsyyfzLhSCIST0ViyXgivR9LptIcSVPvV95sbNiRgaFBU/fZXstQ94XrXdaxk5YbneZrpyyjp80jHT3HO0yHLeZzw4P9A0N9XdZDfaPHrKMnei62P3zywLZi+weFjwl3XlZ15gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20dbd243edffbecd4f474a8c9572beb4/e4706/InstarVision-Photoseries_05.avif 230w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/d1af7/InstarVision-Photoseries_05.avif 460w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/7f308/InstarVision-Photoseries_05.avif 920w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/e3723/InstarVision-Photoseries_05.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/20dbd243edffbecd4f474a8c9572beb4/a0b58/InstarVision-Photoseries_05.webp 230w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/bc10c/InstarVision-Photoseries_05.webp 460w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/966d8/InstarVision-Photoseries_05.webp 920w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/a9a89/InstarVision-Photoseries_05.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/20dbd243edffbecd4f474a8c9572beb4/81c8e/InstarVision-Photoseries_05.png 230w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/08a84/InstarVision-Photoseries_05.png 460w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/c0255/InstarVision-Photoseries_05.png 920w", "/en/static/20dbd243edffbecd4f474a8c9572beb4/2bef9/InstarVision-Photoseries_05.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/20dbd243edffbecd4f474a8c9572beb4/c0255/InstarVision-Photoseries_05.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`5`}</strong>{` - The software will now display the content of your Photoseries folder. Click on `}<strong parentName="p">{`Select All`}</strong>{` to do some global adjustments to the image.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a34172fe4a01ab1037c22201e43dc2ae/2bef9/InstarVision-Photoseries_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjklEQVQozzXQWU8TUQAF4PkPPPJXXOISg0YRYwAhUFkjiQRxiwmJ0WBSH4wGgySCiBYJUhaB0NKW0o3ptNPSlqllqZQKlDJLO/dOZ+gyXabXoPE8nJyX7+VgT56ru5+9GNLMfBweGRsbXdbrhz68V6tf9vf3DX+d/qw1vB03Dn4zvNMYBicMGq1+XKvTTOimpucX5n9g9U2NRHAnMzPHc0lWSEuiCKTMkZBNn0jbMdFJRkRe2E/J6RNJlvPZXD5fKCSh9CsaY1kGu1l/Zc9kQJWV+zpTNCEwDMfxAs0CLglpDjIcZFhAJyAUTsrlcrFUQgixPIyzvCznsM6emoO5aVRRQQ6MGHxhG+63u4Nmh89GUBZnAPeGzGt+85qf2owghBRFOcUJnub4sqJgnX0XErub6My5ZDjMAJBMsBACCHnhXwsQAB4KgAdAENMAiikpcxA7ZrhkqVTCmrta2O0tVHWVXFpeWXNb7U4b7jZbHFaHy2J3ush1mx3HCdKBu9Y3QgTpJ9cpt8efBLBYLGKjn0YTDI0Yhgr8JLwb5HrQ6w+5PAHCE9ja2UUIlctl9D//tpASAUyd4qXFWQB4hBCfSrO8lBQkIGYEKStIWTF9eq4sF/KFQk7O5+R8JicXikqcphmGVhQFU7/qg3+xy+01rNjNFnzVThhMVrMFN5odq3a33mhZtTkX9NZZnWNi1jQ5b51dNCa445KiYD3dbbE4rSBEhQ+JjYg7GPUEfxOBiJuKklQ0GI57Q/v+rUPf5gG1c+TbPAztMR4qODn1RafTY20d7TW36+6oOuraH1W3Pr2menxd9bBa9aCm6X5V7eWqW+dVzQ33OlrbOxtu3D3b2Huppbeqtuvim4HX2u/aP029AP4106ZtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a34172fe4a01ab1037c22201e43dc2ae/e4706/InstarVision-Photoseries_06.avif 230w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/d1af7/InstarVision-Photoseries_06.avif 460w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/7f308/InstarVision-Photoseries_06.avif 920w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/e3723/InstarVision-Photoseries_06.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a34172fe4a01ab1037c22201e43dc2ae/a0b58/InstarVision-Photoseries_06.webp 230w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/bc10c/InstarVision-Photoseries_06.webp 460w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/966d8/InstarVision-Photoseries_06.webp 920w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/a9a89/InstarVision-Photoseries_06.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a34172fe4a01ab1037c22201e43dc2ae/81c8e/InstarVision-Photoseries_06.png 230w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/08a84/InstarVision-Photoseries_06.png 460w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/c0255/InstarVision-Photoseries_06.png 920w", "/en/static/a34172fe4a01ab1037c22201e43dc2ae/2bef9/InstarVision-Photoseries_06.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a34172fe4a01ab1037c22201e43dc2ae/c0255/InstarVision-Photoseries_06.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`6`}</strong>{` - Open the edit menu to get access to the photo editing tools available in the Photo Gallery app. If you apply those tools now, they will be applied to all images equally. You might want to select some of the images to do individual adjustments. Please be aware that the overall look of images close to each other has to be similar (see also step 7).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14e1e78e217c5d97c42c94ebdfd34626/2bef9/InstarVision-Photoseries_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACdklEQVQozy3Pa0sUUQCA4fljQVAU+EHTsKSsrG0qzAIRTawPSSmEgZElkbf9kjc00V3N2cvMnJkzM2fmzH1nd9U108TUvXhHyb2cWOgHPLy8VHPHmyctrz4Ojn7q7x/43Dc3NdnX29PV9bqzs/3LyPjg2Py74WCvP/h+JNjrDwx+mx0enRnyz46Of5+Znqbq7tTjxK/D45O99c10MvXHS+5adiLmWG4qmVxyXSeby52c/SWlUqFYyhdLhJC9w5Pltd+7OztU9bWLq6kVQojtJCBAGkQmtjgJi5oDFEvSXaCYiuGJ2EVmXFQdCbuKmcjsH52dnlI0fXV9Y40QIqpmICIwvLIIUIABiyycj4hhoMwx/A8WBkMgCvFCBDK8EgYod3BUKBQouu1CJrdDCDFiKaC6ku5JRgJoMajHRezJRpJTHFGPAzWmOisC9mRzCWInnd3L5/PU3Yf1u+k0IURzlljZFtSYoHmsbAE1xim2iGMRyeBVNypbAvYikskhl5P0TDZbxkNfBzKZMlbMeEg0OOSwssUALSqbYajzyGaAxspGWFQBskKCKiDMQ+Xw6Dh/fk4F5qZyuRwhRHc8VtIEZIiqyUkIqgYvY4htFiIe2azswHLZ4pHHSTibzZSfu9++PNjfKz87HvcfW6yoisgAMlawzUMEkBOVXagnItDm1USYl7e3NorFEvW8ybe5tX1eIKrzk9OWRTMFzVWAlwFORqDNKs4CjyPQZATMyjYj6LzqhgAYm/AHAkHqgc9XWVNbe/t+Hd1yne6o9rXX+Fprfa03fM1V9RWVNy/TdMOzxseNTfdq6EsNLyoetVXdenql50P35PjEPwmO7OXS061kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14e1e78e217c5d97c42c94ebdfd34626/e4706/InstarVision-Photoseries_07.avif 230w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/d1af7/InstarVision-Photoseries_07.avif 460w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/7f308/InstarVision-Photoseries_07.avif 920w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/e3723/InstarVision-Photoseries_07.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14e1e78e217c5d97c42c94ebdfd34626/a0b58/InstarVision-Photoseries_07.webp 230w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/bc10c/InstarVision-Photoseries_07.webp 460w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/966d8/InstarVision-Photoseries_07.webp 920w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/a9a89/InstarVision-Photoseries_07.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14e1e78e217c5d97c42c94ebdfd34626/81c8e/InstarVision-Photoseries_07.png 230w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/08a84/InstarVision-Photoseries_07.png 460w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/c0255/InstarVision-Photoseries_07.png 920w", "/en/static/14e1e78e217c5d97c42c94ebdfd34626/2bef9/InstarVision-Photoseries_07.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14e1e78e217c5d97c42c94ebdfd34626/c0255/InstarVision-Photoseries_07.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`7`}</strong>{` - You can adjust the Contrast and Saturation as well as reduce the image noise ("graininess"). If there are stark differences in brightness between two images next to each other, it is recommended to go through them one by one and equalize them - otherwise they will show up as a annoying flicker in the final movie!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/2bef9/InstarVision-Photoseries_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVQozyWNW0/TYABA+w/4BfqkP0NfjDxoBEQ0IqgwYCRC1JdJkMREMCQGDeEBw4AwSdRBjDgYl21d19vWb+23bu0ubKPboODCgHWwIBfbzprJy8l5OTnIi/7BHsub8Rn7+IR1esrqXPgxNjY6PPx2aOj1hG1uyr78wbY8Nrv8cbZK27elz3bHzKxjbn5h8ecC8qDlUSglH5XL+d2CUlSk5DYOcsqukj1SNVVVVU3TtAtqqnb+R9V1TSmfbGz+2ivsIvX11zMZyTAMICQwpzv/fqRwdEr6WS/NYVSQBLyHYIgARElAAR4lAB6ANCcWD4/PTk+Rzo5aeXvTMIyQvJ/+NFOpqQnjgBKkoLARiKTZaJbmk4yQoflUUKwSCBIFE8XDckXXkS7LtQOlYBgGI+0kLAMGgjA2OxpKEoGwh4I4EFw4i1Wdw4HgJjivn0cp7qB0qGka8tjUure/ZxgGm96Cc46zK1dxmMCZCAEElIYEEDwk62PCF47S0MdEfH5YVErVeNI6uf8/pmMbQZv9/NJlN8Wv4ixK8y4S+hhhDWe9/rCL4HyM4CJ5Dx2h2Ojx7xNVVZFFx/disVg9J2X/ki/9xEzAFAZiBLfuA3E6lHJTvDcguqkwHoy5SB4NiCjFKcqBruvIu8H+UqlUPQPeiTGrXNSFgxWU9hCsCweYn686ya5hAcwPV720m+Cca2g+L1cqFaS3xyTv5FX9b1zahrEsH83w61swngsnZRjPheI5IKS5WJYVpVB8MyhKYnqHDLJfvk47nStIm8l0p6Gxqflps9nSZB6429nf2NHX1PnqvullXeutuoc3zV3tz5919/S23eu60d53u7uvoaW3dmR0eN4+/w+sJehqco+UcwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/e4706/InstarVision-Photoseries_08.avif 230w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/d1af7/InstarVision-Photoseries_08.avif 460w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/7f308/InstarVision-Photoseries_08.avif 920w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/e3723/InstarVision-Photoseries_08.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/a0b58/InstarVision-Photoseries_08.webp 230w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/bc10c/InstarVision-Photoseries_08.webp 460w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/966d8/InstarVision-Photoseries_08.webp 920w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/a9a89/InstarVision-Photoseries_08.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/81c8e/InstarVision-Photoseries_08.png 230w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/08a84/InstarVision-Photoseries_08.png 460w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/c0255/InstarVision-Photoseries_08.png 920w", "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/2bef9/InstarVision-Photoseries_08.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8b84d100b76e63e1eb241b3aa1e2d7b5/c0255/InstarVision-Photoseries_08.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`8`}</strong>{` - Now open the `}<strong parentName="p">{`Create`}</strong>{` menu and choose the `}<strong parentName="p">{`Movie`}</strong>{` icon. This will open the `}<strong parentName="p">{`Movie Maker`}</strong>{` and load all your selected images from the Gallery into a movie timeline for you.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc95443df1912ed3b73a627349a1648d/2bef9/InstarVision-Photoseries_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeUlEQVQoz03OzU/TcBgH8P4PXjzrnaMHEw+ePOPBw4IRRTTGGGOiXtRoYkRxERJAJxuDgaxb13ZlfW/XX9eBEoUQGIa9sLHJGKx7bWeMDLb1Z0aM8cmTJ9/L58kXefT8zdOh0XmS/+RFAxga1TR0dnJibNhufzkXYFA6+j4QdRKaA9dcZBSjVZwGc1TEMzO/QOCI7drVlcTu161cfm+3UNjd2y8Y+biRT+mVSrl0UK3o1YpeKRe7oVqqVEq1avlAL25tbBTyeeRy78VMejuZzsXiufVYei2vl+yjpQ8fv+uleDKV2skmd7LJbPcmMplEOrOZSO0VS7+arVq9jtwavKTrhRk0NDLyTgq61TBeP3um2NPjl0UsSPiDpJ8iMYoMhKggRwdZxovja7HY0dGxYdSRwccXfh+aT4bGbX29b1/033lok0+fMm8MMF+WhXBYVFVBASFBZiSFVzVR1WgpvLWdhhCapolcv93faDQAEMbG7W7n6AQxtXH+3MHde9TKWoiXCI4nWB6lWF+QRQnaRzEeP7myvgkhNAwDmXFP12q14+MWhNBqty0I4eTkj1ev8chnIsSjZBf4/1uPj1j9h3mWMk3j8LDZ6XTa7Xar1f2SLeyTnEhLgJEBLYGQqDAyYMMqF1ZJVtyMJ//WHh569rNhHjWblmWd+A60rGxuN8hJjKTQksLKgJUBF1Y5BfCKuiBIJ9jq4gf3B8vVeqvVgSdjWd3ixXJZUDVZW+bBIqtEWCXCKRFBWRTBEi2qifQOhJDjOGTg5sAVW5/T46WEKM6CAAMIVp0nWMe01+FxuebcGE4ECNKPY07UOeWbGnc7XOjs0rdVzI/9Abfi7yJgQimiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc95443df1912ed3b73a627349a1648d/e4706/InstarVision-Photoseries_09.avif 230w", "/en/static/fc95443df1912ed3b73a627349a1648d/d1af7/InstarVision-Photoseries_09.avif 460w", "/en/static/fc95443df1912ed3b73a627349a1648d/7f308/InstarVision-Photoseries_09.avif 920w", "/en/static/fc95443df1912ed3b73a627349a1648d/e3723/InstarVision-Photoseries_09.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc95443df1912ed3b73a627349a1648d/a0b58/InstarVision-Photoseries_09.webp 230w", "/en/static/fc95443df1912ed3b73a627349a1648d/bc10c/InstarVision-Photoseries_09.webp 460w", "/en/static/fc95443df1912ed3b73a627349a1648d/966d8/InstarVision-Photoseries_09.webp 920w", "/en/static/fc95443df1912ed3b73a627349a1648d/a9a89/InstarVision-Photoseries_09.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc95443df1912ed3b73a627349a1648d/81c8e/InstarVision-Photoseries_09.png 230w", "/en/static/fc95443df1912ed3b73a627349a1648d/08a84/InstarVision-Photoseries_09.png 460w", "/en/static/fc95443df1912ed3b73a627349a1648d/c0255/InstarVision-Photoseries_09.png 920w", "/en/static/fc95443df1912ed3b73a627349a1648d/2bef9/InstarVision-Photoseries_09.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc95443df1912ed3b73a627349a1648d/c0255/InstarVision-Photoseries_09.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`9`}</strong>{` - Now open the `}<strong parentName="p">{`Video Tools`}</strong>{` to Edit your Video Properties.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/2bef9/InstarVision-Photoseries_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACaklEQVQozz2QzW/ScByH+6d4MvHgX6CH3TyZRU/qzGLcy8mYXfQ0Y+JhWbLojMYsbpExeSmjtIW2v1L6BhtsCB1b2EZZCwMZm8KISgYUhNKfgWw+h0++lyf55kFevJ6fnVv00hJO+hkmkEomsXX7ysqHpaVFnJEComIHipNNrIGkm1MYKQHEOB5KoF7ST+DIw/HHO1o5X7lod4xet9Mxza5Vt/oNOKA/XAtC8+oe0DPNs0qlWq0i9++NFArH+e+nqbSezBQ1IVyKxvWz6r6qHmi6mstlc3k1n8/o+uGRdnik7R2qxfJZq9NtNpvI1OSdWu2Hy8e9W1gAInYycku7O7qpZr24F8UxFMfcOIYSOBYgSUARgHZhvtR+utfrt1otZOrl7b/dizdvP489Gn01Ny3fvLZ64zq5FRNFmeV4lhf4EB+kARUSg3KEkyNUSMhoOQihYRjIk+nxRqMZjUof389/ci+nJh9+m3m2oWb2lYNCvqyfVArl8/C24sT8KE6jJG334Ind9KW8+sVWr9eHXaxBIsOAppkrlmI72Wzpt1qs5U7rwtauy0d6/IyHpO2oT9m7klmGbDQuLMsyh/StQVX9uMjK8UgiE9tR43s6kLaokMgIMhBknOHSahZC2DbayPzcbLPZsCyrP8Ts9SCEhVKZZHlGCNO8DIQww8uMIAFBYkXZHwxdyu02MvN86tef+vBr6//+PK9x8ga/sR2UNoEYAVKYESNBaZOTojQnZ/VjCKEgisjTiYkHY+MODwnkOMXHAnyMEmJeire5fDb0q2Pd6afoAEUTFLmG2x2EY9lpW/O5lfQBgRP/AHVFAL9DfuqoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b3642f1dcbcf20cf257946a224fe4fe/e4706/InstarVision-Photoseries_10.avif 230w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/d1af7/InstarVision-Photoseries_10.avif 460w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/7f308/InstarVision-Photoseries_10.avif 920w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/e3723/InstarVision-Photoseries_10.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3b3642f1dcbcf20cf257946a224fe4fe/a0b58/InstarVision-Photoseries_10.webp 230w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/bc10c/InstarVision-Photoseries_10.webp 460w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/966d8/InstarVision-Photoseries_10.webp 920w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/a9a89/InstarVision-Photoseries_10.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b3642f1dcbcf20cf257946a224fe4fe/81c8e/InstarVision-Photoseries_10.png 230w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/08a84/InstarVision-Photoseries_10.png 460w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/c0255/InstarVision-Photoseries_10.png 920w", "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/2bef9/InstarVision-Photoseries_10.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3b3642f1dcbcf20cf257946a224fe4fe/c0255/InstarVision-Photoseries_10.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`10`}</strong>{` - To get a movie in Cinema Quality, you will want to aim for a 24 images per second. You have to set the Duration to 0.04s. If you have a small amount of images for your timelapse, you might want to consider to type in a higher number (0.06s - 0.2s) here - or the timelapse will end up to be very short.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/2bef9/InstarVision-Photoseries_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbElEQVQozy1QyW8SYRyd/8vEmzftSU3UeFC5GqMxMdHEXmzSxmi82BSbLpalZZjl+775Bmag0F1augCVtgwDwzZDC9IdWWT5GaLv9JKXt+Qxr9+PvRv55BbVOadT4NiV5SWnwz4x/vHL55F5nrLy+gS/PiWsTAqrTrou+FcFGnagJcd317xjjrn36OHOUSGyn8nlS4aRy+dLv2pF3cyncmamYOpZo5Q3isVSrmBGY9mUnstZlUzGSMYS6VSKGRq6qae1k0ql0Wxb1fPLerPe6u1qhVAkury1t7yxuRON7MbiP6Jxnm6EViNr2wk9nam3e6ZlMTbbrayhF/NmrXJaMqvFcsWqnOnpNEE8lTClhBCMRB4j3i8jmSLO496LxwDguFxmbG9uVGvl8qF+caDVamfnF5dX9UZK0yQiShIiBBEiYiRgJCBRIFj0sgv7iTgAlMsW88T2uNJqnLJc4+t4tQe/r+t/un1N05DgJUjA4sA2IGgQIUtYIvjwINkHsCyLmf02eQrQfPWie/vOJcD1Vb3Zamezhk+WFUVR/P+g+GQfpbIsy7zAJ5PJbqdrlkqMj3AXAO0JOwwPA0C/0wEAI5cXRSRRSiRZolTEWCQYEYwl4uW8iURiMNuymLEPb68A2lNT/dHRHkC31RqYjayX8xDCUcz6JK9KWUVi/ZJHlTmKXD8T2/8Pe/ncVm00O1tRCIUHzd3uQDg+CaiB8GI4FFwMqKqiqGowHFxcCSoBCQna0SEAsKyXeWp7dvf+A7uLFZY2PTjgIUGWBF28b9otTC+4XZyHUIXKKvHJLjzvQm67Y3rG41zb3JqZmf0LDK75s0NdI34AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/e4706/InstarVision-Photoseries_11.avif 230w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/d1af7/InstarVision-Photoseries_11.avif 460w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/7f308/InstarVision-Photoseries_11.avif 920w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/e3723/InstarVision-Photoseries_11.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/a0b58/InstarVision-Photoseries_11.webp 230w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/bc10c/InstarVision-Photoseries_11.webp 460w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/966d8/InstarVision-Photoseries_11.webp 920w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/a9a89/InstarVision-Photoseries_11.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/81c8e/InstarVision-Photoseries_11.png 230w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/08a84/InstarVision-Photoseries_11.png 460w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/c0255/InstarVision-Photoseries_11.png 920w", "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/2bef9/InstarVision-Photoseries_11.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49c06a3fdc6ad884b6f6e2712fc97a64/c0255/InstarVision-Photoseries_11.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`11`}</strong>{` - Now open the `}<strong parentName="p">{`File Menu`}</strong>{` and choose a preset to save your movie in. It is recommended to choose a 720p Preset for your HD camera and a VGA preset for your VGA camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff771a09938b789b2ebaecf4f736e88e/2bef9/InstarVision-Photoseries_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeElEQVQozy3OW0/TYADG8X4d/QTeeOWtGjAYFE3ISAx3iokXJopGLgghiCGGKG7ruq1d57oe1r7rugE7uFPpjuAQAoNFE+bsCJnr2LHra0D+F8/dL3mQ56/mX84tuvn1rxQFBJ+cTrtdFsuX5U+rS6w/DCJ5mz/rENJ2IHtCCtiUQSjhEVOkhyFwJ3L/0UTh8KT8u9loau1Op98b/G2f1ZoNrQe1nt48b/e7571+v9PVf9XajVa7a8DWeeekVq9UKsjtOzcrx0fl45+pTCmV2c0Vd+XC/redcljJRbdysZScyci5wnZiK+8R4qFIYiOZ/bG73+7pf/6oiMl0S1WrOCV9WF7e8NlikisocoqikKSdcFoIHHU4ULvd7CbtfkBxHIU7bPl8zjCgqqqI6cWNbl97t/T5wcTdhbeTM8/Gxh/fEwMSAH6vl6Fpmhd4wLI+ihKAIPoByzKl0ncIYb1eR0zTJq3VSiTDKx/fW9BVFFtxYGuFXDaZlNudnqEPjU4XQigFQ1YUIwjSbEFlWbnCNqu10WjAy3QIh4YBIaxUKhvr62lFkcPhOMdF4wkvReOEiyTdViuqKJkLrKqICFhNaxqGMdT14VAfDAYQwoODQzfhcvFCZPZNaXQU9/Ecw/ACEARAUdT29g6E8LReRxbmZ1st7QJfpus6hLB8dERTXnpzMzM9Xbt+jZUkwPM87wNAoGm6WCxe4ZmnT07PLm4bl4f/b7VaDYpiIJneWlg8HhnhYjG/AAJiUAqEBJ+wt7cPIeRYFjFNTY2NP8QIjy8Up8UILUaZQJRkRDNGrFEudu51dnKSloIM48Eoq92LrdnNmAfPFHecTvwfNDLzHMt4g5UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff771a09938b789b2ebaecf4f736e88e/e4706/InstarVision-Photoseries_12.avif 230w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/d1af7/InstarVision-Photoseries_12.avif 460w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/7f308/InstarVision-Photoseries_12.avif 920w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/e3723/InstarVision-Photoseries_12.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff771a09938b789b2ebaecf4f736e88e/a0b58/InstarVision-Photoseries_12.webp 230w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/bc10c/InstarVision-Photoseries_12.webp 460w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/966d8/InstarVision-Photoseries_12.webp 920w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/a9a89/InstarVision-Photoseries_12.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff771a09938b789b2ebaecf4f736e88e/81c8e/InstarVision-Photoseries_12.png 230w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/08a84/InstarVision-Photoseries_12.png 460w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/c0255/InstarVision-Photoseries_12.png 920w", "/en/static/ff771a09938b789b2ebaecf4f736e88e/2bef9/InstarVision-Photoseries_12.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff771a09938b789b2ebaecf4f736e88e/c0255/InstarVision-Photoseries_12.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`12`}</strong>{` - After processing - just push play to watch your timelapse!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      